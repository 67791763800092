.zhegnshu-wrap .el-tag {
  background-color: transparent;
  color: black;
  border: 1px solid #DCDFE6;
  cursor: pointer;
}
.certificateTable /deep/ .el-table__header-wrapper .el-table-column--selection .cell {
  display: none !important;
}
.muban1 {
  background-color: #f7f7f7;
  margin-left: 7rem;
  padding: 20px;
}
.muban1 /deep/ .el-form-item__content {
  margin-left: 0 !important;
}
.crUpload-wrap {
  display: flex;
}
.crUpload-wrap .img-wrap {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  border: 1px dashed #e2e2e2;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.crUpload-wrap .img-wrap:hover .delete-wrap {
  display: flex;
}
.crUpload-wrap .img-wrap img {
  display: block;
  height: 100%;
  width: 100%;
}
.crUpload-wrap .img-wrap .delete-wrap {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
}
.crUpload-wrap .img-wrap .delete-wrap .el-icon-delete {
  font-size: 24px;
  color: white;
  cursor: pointer;
}
.crUpload-wrap .crUpload {
  width: 150px;
  height: 150px;
}
.crUpload-wrap .crUpload:hover /deep/ .el-upload {
  border: 1px dashed #409EFF;
}
.crUpload-wrap .crUpload /deep/ .el-upload {
  background-color: white;
  border-radius: 4px;
}
.crUpload-wrap .crUpload /deep/ .el-upload--text {
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crUpload-wrap .hide {
  display: none;
}
.fitUpload-wrap .fitUpload {
  width: 150px;
  height: 150px;
}
.fitUpload-wrap .fitUpload:hover /deep/ .el-upload {
  border: 1px dashed #409EFF;
}
.fitUpload-wrap .fitUpload /deep/ .el-upload {
  background-color: white;
  border-radius: 4px;
}
.fitUpload-wrap .fitUpload /deep/ .el-upload--text {
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fitUpload-wrap .hide {
  display: none;
}
.fitUpload-wrap .fitUpload-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.fitUpload-wrap .fitUpload-item .img-wrap {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  border: 1px dashed #e2e2e2;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.fitUpload-wrap .fitUpload-item .img-wrap:hover .delete-wrap {
  display: flex;
}
.fitUpload-wrap .fitUpload-item .img-wrap img {
  display: block;
  height: 100%;
  width: 100%;
}
.fitUpload-wrap .fitUpload-item .img-wrap .delete-wrap {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
}
.fitUpload-wrap .fitUpload-item .img-wrap .delete-wrap .el-icon-delete {
  font-size: 24px;
  color: white;
  cursor: pointer;
}
