
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.zhegnshu-wrap{
    .el-tag{
        background-color: transparent;
        color: black;
        border: 1px solid #DCDFE6;
        cursor: pointer;
    }
}
.certificateTable{
    /deep/.el-table__header-wrapper{
        .el-table-column--selection{
            .cell{
                display: none !important;
            }
        }
    }
}
.muban1{
    background-color: #f7f7f7;
    margin-left: 7rem;
    padding: 20px;
    /deep/.el-form-item__content{
        margin-left: 0 !important;
    }
}
.crUpload-wrap {
    display: flex;
    .img-wrap{
        width: 150px;
        height: 150px;
        margin-right: 20px;
        border: 1px dashed #e2e2e2;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        &:hover{
            .delete-wrap{
                display: flex;
            }
        }
        img{
            display: block;
            // max-height: 100%;
            height: 100%;
            // max-width: 100%;
            width: 100%;
        }
        .delete-wrap{
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            display: none;
            justify-content: center;
            align-items: center;
            .el-icon-delete{
                font-size: 24px;
                color: white;
                cursor: pointer;
            }
        }
    }
    .crUpload{
        width: 150px;
        height: 150px;
        &:hover{
            /deep/.el-upload{
                border: 1px dashed #409EFF;
            }
        }
        /deep/ .el-upload{
            background-color: white;
            border-radius: 4px;
        }
        /deep/.el-upload--text{
            height: 100% !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .hide{
        display: none;
    }
}
.fitUpload-wrap{
    .fitUpload{
        width: 150px;
        height: 150px;
        &:hover{
            /deep/.el-upload{
                border: 1px dashed #409EFF;
            }
        }
        /deep/ .el-upload{
            background-color: white;
            border-radius: 4px;
        }
        /deep/.el-upload--text{
            height: 100% !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .hide{
        display: none;
    }
    .fitUpload-item{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .img-wrap{
            width: 150px;
            height: 150px;
            margin-right: 20px;
            border: 1px dashed #e2e2e2;
            border-radius: 4px;
            position: relative;
            overflow: hidden;
            &:hover{
                .delete-wrap{
                    display: flex;
                }
            }
            img{
                display: block;
                // max-height: 100%;
                height: 100%;
                // max-width: 100%;
                width: 100%;
            }
            .delete-wrap{
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.5);
                display: none;
                justify-content: center;
                align-items: center;
                .el-icon-delete{
                    font-size: 24px;
                    color: white;
                    cursor: pointer;
                }
            }
        }
    }
}
