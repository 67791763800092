























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.bgc-bv:disabled,
.bgc-bv:disabled:hover,
.bgc-bv:focus,
.bgc-bv:hover {
  background-color: #6676ff;
  border-color: #6676ff;
  color: #fff;
}
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
/deep/ .dfOn{
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 18px;
}
/deep/ .dialogTit{
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 15px;
}
/deep/ .dialogContent .el-form-item{
  margin-bottom: 10px;
  color: #f56c6c;
}
